<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <TitleH2 title="Serviço" />
        <ChipGroup
          :loading="loading"
          :tags="servicos"
          :key="keyServicos"
          @changeTag="onChangeServico"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="5">
        <TitleH2 title="Filial"/>
        <AutocompletePaginadoMain
          tituloItem="nome"
          descricaoItem="cnpj"
          mascaraDescricao="##.###.###/####-##"
          firstIcon="card-account-details"
          placeholder="Filial"
          :mensagemItemNaoEncontrado="'Nenhuma filial encontrada'"
          :filled="true"
          :rounded="true"
          :dense="true"
          :loading="loading"
          :items="parceiros"
          :key="keyParceiro"
          @update:value="onChangeParceiro($event)"
          @scrollDown="adicionarMaisParceiros"
          @pesquisa="buscarParceiroPorNome($event)"
          @onBlur="$event && $event.clear && receberParceiros()"
          @onClear="$event && $event.clear && receberParceiros()"
        />
      </v-col>

      <v-col cols="12" lg="5">
        <TitleH2 title="Colaborador" />
        <AutocompleteMain
          firstIcon="account"
          placeholder="Colaborador"
          keyValue="idEC"
          :exibirDetalhes="true"
          :notFound="mensagemSelectColaborador || 'Primeiro selecione uma filial'"
          :loading="loading"
          :nomeItem="'nome'"
          :itemValue="'idEC'"
          :key="keyColaborador"
          :data="colaboradores"
          @eventSelected="onChangeColaborador($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <TitleH2 title="Informe a Agenda" />
        <CalendarMain
          :loading="loadingCalendar"
          :eventDays="agendaColaborador"
          :key="keyCalendario"
          :disponibilidadeColaborador="disponibilidadeColaborador"
          @selectedDate="getTimeLineColaborador($event)"
        />
      </v-col>
      <v-col v-if="agendaDia.length" cols="12" md="6">
        <TitleH2 title="Agenda Informada" />
        <TimelineListMain
          :key="keyTimeLine"
          @edit="editTimeLine(agendaDia)"
          @delete="deleteTimeLineItem($event)"
          :lineList="agendaDia"
        />
      </v-col>
    </v-row>

    <DialogConfirmation
      :width="setLarguraModal(tagDialog)"
      :title="dialogTitle"
      :show="dialog"
      :tag="tagDialog"
      :key="keyModal"
      @close="closeConfirmationModal"
      @confirm="confirmDialog($event)"
    >
      <template v-if="isManual" v-slot:footer>
        <span></span>
      </template>

      <template v-if="tagDialog === 'edit' || tagDialog === 'add'" v-slot:body>
        <div class="mb-3">
          <v-btn-toggle color="primary" mandatory>
            <v-btn :disabled="tagDialog === 'edit'" @click="isManual = false">
              Automático
            </v-btn>
            <v-btn @click="isManual = true"> Manual </v-btn>
            <span class="mx-2 text--gray">
              <span class="d-block">Disponibilidade:</span>
              <span
                v-for="(disponibilidade, index) in disponibilidadeAtual"
                :key="index"
                class="d-block"
                >{{ disponibilidade.horaInicio }} -
                {{ disponibilidade.horaFim }}</span
              >
            </span>
          </v-btn-toggle>
        </div>

        <div v-if="!isManual && tagDialog !== 'edit'">
          <v-text-field
            type="number"
            hide-details="auto"
            v-model="qtdAtendimento"
            @keypress="onlyNumber"
            label="Quantidade de atendimentos"
            min="0"
          ></v-text-field>
        </div>

        <div v-else>
          <v-row align="center">
            <v-col class="pb-0">
              <v-text-field
                type="text"
                v-model="agendaHorario.horaInicio"
                @keypress="onlyNumber"
                v-mask="'##:##'"
                label="Horário Inicial"
                :rules="horarioInicialRules"
                hint="Exemplo: 09:00"
              ></v-text-field>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                type="text"
                v-model="agendaHorario.horaFim"
                @keypress="onlyNumber"
                v-mask="'##:##'"
                label="Horário Final"
                :rules="horarioFinalRules"
                hint="Exemplo: 10:00"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn text color="blue" @click="createAgenda">Confirmar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="horarios.length > 0">
            <v-col class="pt-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">Inicia</th>
                      <th class="text-center">Encerra</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="horario in horarios" :key="horario.horaInicio">
                      <td class="text-center">
                        <ChipMain :tag="{ descricao: horario.horaInicio }" />
                      </td>
                      <td class="text-center">
                        <ChipMain :tag="{ descricao: horario.horaFim }" />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="text-center mt-2">
                <ChipMain
                  :tag="{ descricao: `${horarios.length} Atendimentos` }"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </template>

      <template v-else v-slot:body>
        <div>TEM CERTEZA QUE DESEJA EXCLUIR ESSE AGENDAMENTO?</div>
      </template>
    </DialogConfirmation>
  </v-container>
</template>

<script>
import TitleH2 from '../../components/atoms/title/TitleH2'
import ChipMain from '@/components/atoms/items/ChipMain.vue'
import SwitchMain from '../../components/atoms/buttons/SwitchMain'
import ChipGroup from '@/components/molecules/lists/ChipGroupMain.vue'
import AutocompleteMain from '../../components/atoms/fields/AutocompleteMain'
import AutocompletePaginadoMain from '../../components/atoms/fields/AutocompletePaginadoMain.vue'
import CalendarMain from '../../components/atoms/calendars/CalendarMain'
import TimelineListMain from '../../components/molecules/lists/TimelineListMain'
import globalMethods from '@/mixins/globalMethods'
import DialogConfirmation from '../../components/molecules/dialogs/DialogConfirmation.vue'
import TimePicker from '@/components/atoms/fields/TimePicker.vue'
import api from '@/services/api'
import colaboradorService from '@/services/ColaboradorService'
import institucionalService from '@/services/InstitucionalService'

export default {
  components: {
    TitleH2,
    ChipMain,
    ChipGroup,
    AutocompleteMain,
    AutocompletePaginadoMain,
    CalendarMain,
    TimelineListMain,
    SwitchMain,
    DialogConfirmation,
    TimePicker
  },

  mixins: [globalMethods],

  data() {
    return {
      disponibilidadeAtual: null,
      agendaId: null,
      colaborador: null,
      servicoId: null,
      parceiroId: null,
      dataCalendar: null,
      qtdAtendimento: null,
      loadingCalendar: false,
      loading: false,
      isManual: false,
      dialog: false,
      servicoEstaSelecionado: false,
      keyServicos: 0,
      keyParceiro: 0,
      keyColaborador: 0,
      keyCalendario: 0,
      keyTimeLine: 0,
      keyModal: 0,
      paginaAtualSelectParceiro: 0,
      tagDialog: '',
      mensagemErroPadrao: 'Falha na conexão. Por favor, tente mais tarde.',
      mensagemSelectColaborador: '',
      servicos: [],
      disponibilidadeColaborador: [],
      horarios: [],
      parceiros: [],
      colaboradores: [],
      agendaColaborador: [],
      agendaDia: [],
      horarioInicialRules: [
        (value) => value?.length > 4 || 'Insira uma hora válida'
      ],
      horarioFinalRules: [
        (value) =>
          this.agendaHorario.horaInicio < value ||
          'Insira horário maior que o inicial',
        (value) => value?.length > 4 || 'Insira uma hora válida'
      ],
      agendaHorario: {
        horaFim: null,
        horaInicio: null
      },
    }
  },

  created() {
    this.loadingCalendar = true;
    this.todosServicos();
    this.receberParceiros();
    this.loadingCalendar = false;
  },

  methods: {
    receberParceiros() {
      const paginacao = { page: 0, size: 10 };
      institucionalService
        .filiaisAtivas(paginacao)
        .then(({ data }) => {
          this.parceiros = data.content;
        });
    },

    adicionarMaisParceiros() {
      const paginacao = { page: ++this.paginaAtualSelectParceiro, size: 10 };
      institucionalService
        .filiaisAtivas(paginacao)
        .then(({ data }) => {
          if(data.numberOfElements <= 0) {
            this.paginaAtualSelectParceiro = -1;
            return;
          }

          this.parceiros = [...this.parceiros, ...data.content];
      });
    },

    buscarParceiroPorNome(termo){
      const tamanhoTermo = termo.length;
      if(tamanhoTermo === 0) {
        this.paginaAtualSelectParceiro = -1;
        this.receberParceiros();
        return;
      }

      institucionalService.filialPorNome(termo)
        .then(({ data }) => {
          this.parceiros = data;
        });
    },

    onChangeServico(servico) {
      this.$root.vtoast.close();
      this.colaboradores = [];
      this.agendaColaborador = [];
      this.disponibilidadeColaborador = [];
      this.agendaDia = [];

      this.servicoId === servico.id
        ? this.servicoEstaSelecionado = !this.servicoEstaSelecionado
        : this.servicoEstaSelecionado = true;

      const reset = {
        parceiro: true, colaborador: true,
        calendario: true, timeline: true
      };
      this.resetarComponentes(reset);

      if(!this.servicoEstaSelecionado) return;
      this.servicoId = servico.id;
      this.agendaColaborador = [];
      this.disponibilidadeColaborador = [];
      this.agendaDia = [];
    },

    onChangeParceiro(parceiroId) {
      this.$root.vtoast.close();
      this.agendaColaborador = [];
      this.disponibilidadeColaborador = [];
      this.agendaDia = [];
      this.parceiroId = parceiroId;
      this.mensagemSelectColaborador = "";

      const reset = {
        colaborador: true,
        calendario: true,
        timeline: true,
      };
      this.resetarComponentes(reset);
      this.receberColaboradoresPorParceiro(parceiroId);
    },

    onChangeColaborador(colaborador) {
      this.$root.vtoast.close();
      this.agendaColaborador = [];
      this.disponibilidadeColaborador = [];
      this.agendaDia = [];
      if(!colaborador) {
        this.resetarComponentes({calendario: true});
        return;
      }

      this.colaborador = colaborador;
      this.disponibilidadeColaboradorCalendario(colaborador.id);
      const filtro = {
        colaborador: colaborador.id,
        parceiro: this.parceiroId,
        servico: this.servicoEstaSelecionado ? this.servicoId : null,
      }
      this.getAgendaColaboradorCalendario(filtro);
    },

    receberColaboradoresPorParceiro(parceiroId) {
      this.colaboradores = [];
      if(!parceiroId) return;

      colaboradorService.colaboradorPorFilial(parceiroId)
        .then(({ data }) => {
          if(data.length <= 0) {
            this.mensagemSelectColaborador = `Nenhum Colaborador vinculado a este Parceiro`
            this.$root.vtoast.show({
            status: 'warning',
            text: 'Nenhum Colaborador vinculado a este Parceiro.',
            title: 'Atenção!',
          });

          return;
          }

          this.colaboradores = data.map(colaborador => {
            return {
              ...colaborador,
              avatar: `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=${colaborador.nome}`,
            }
          });
          this.mensagemSelectColaborador = "";
        })
        .catch((erro) => {
          const mensagemErro = erro.response.data;
          this.$root.vtoast.show({
            status: 'error',
            title:  mensagemErro.titulo || this.mensagemErroPadrao,
            text: mensagemErro.mensagemUsuario || '',
          });
        });
    },

    setLarguraModal(tipoModal) {
      if(tipoModal === 'edit' || tipoModal === 'add') return 450;
      if(tipoModal === 'delete') return 550;
    },

    closeConfirmationModal() {
      this.dialog = false;
      this.keyModal++;
      this.agendaId = null;
      this.horarios = [];
      this.isManual = false;
      this.agendaHorario = {};

      const filtro = {
        colaborador: this.colaborador.id,
        parceiro: this.parceiroId,
        servico: this.servicoEstaSelecionado ? this.servicoId : null,
      };
      this.getAgendaColaboradorCalendario(filtro);
      api
        .getHorariosByColaborador({
          data: this.dataCalendar,
          servicoId: this.servicoId,
          colaboradorId: this.colaborador.id
        })
        .then(({ data }) => {
          this.agendaDia = data
        })
    },

    createAgenda() {
      if (this.agendaHorario.horaInicio < this.agendaHorario.horaFim) {
        if (!this.servicoEstaSelecionado) {
          this.$root.vtoast.show({
            status: 'warning',
            title: 'Selecione um Serviço para realizar o Agendamento',
            text: 'ATENÇÃO!',
          });

          return;
        }

        const agenda = {
          colaborador: this.colaborador.id,
          especializacao: this.colaborador.idEspecializacao,
          dia: this.dataCalendar,
          servicoDisponivel: this.servicoId
        }

        if (this.agendaId || this.tagDialog === 'edit') {
          api
            .postHorarioAgendaManual({
              agenda: this.agendaId,
              horaInicio: this.agendaHorario.horaInicio,
              horaFim: this.agendaHorario.horaFim
            })
            .then(({ data }) => {
              this.horarios = data
              this.agendaHorario = {
                horaFim: null,
                horaInicio: null
              }
            })
            .catch((err) => {
              this.$root.vtoast.show({
                status: 'error',
                text: err.response?.data.mensagemUsuario,
                title: err.response?.data.titulo,
              })
            });

          return;
        }

        api
          .postAgendaManual(agenda)
          .then(({ data }) => {
            this.agendaId = data.id

            api
              .postHorarioAgendaManual({
                agenda: this.agendaId,
                horaInicio: this.agendaHorario.horaInicio,
                horaFim: this.agendaHorario.horaFim
              })
              .then(({ data }) => {
                this.horarios = data
                this.agendaHorario = {
                  horaFim: null,
                  horaInicio: null
                }
              })
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo,
            })
          });
      } else {
        this.$root.vtoast.show({
          status: 'warning',
          text: 'Insira um intervalo de horário válido!',
          title: 'Atenção!',
        })
      }
    },

    disponibilidadeColaboradorCalendario(colaboradorId) {
      this.loadingCalendar = true;
      api
        .getDisponibilidadeColaborador(colaboradorId)
        .then(({ data }) => {
          this.disponibilidadeColaborador = data.map((date) => date.dia)
        })
        .catch(() => {
          this.disponibilidadeColaborador = []
        })
        .then(() => this.loadingCalendar = false );
    },

    getAgendaColaboradorCalendario({ colaborador, parceiro, servico }) {
      const filtro = { colaborador: colaborador, parceiro: parceiro, servico: servico }

      api.getAgendaFiltro(filtro)
        .then(({ data }) => {
          this.agendaColaborador = data.map((agenda) => {
          return this.dateFormatISO(agenda.data)
          });
        })
        .catch(() => {
          this.agendaColaborador = []
        });
    },

    todosServicos() {
      api.getServicos().then(({ data }) => {
        this.servicos = data
      });
    },

    editTimeLine(event) {
      this.agendaId = event[0].idAgenda
      this.openModalAntedimentos('edit')
    },

    confirmDialog() {
      if (this.tagDialog === 'add') {
        if (this.isManual) return;
        this.agendaPost();

      } else if (this.tagDialog === 'delete') {
        this.deleteAgendaById();
        this.dialog = false;
      }

      this.qtdAtendimento = null;
    },

    deleteTimeLineItem(item) {
      this.idAgenda = item.idAgenda
      this.openModalAntedimentos('delete')
    },

    deleteAgendaById() {
      api
        .deleteAgendaById(this.idAgenda)
        .then((_) => {
          this.keyTimeLine++;
          this.agendaDia = [];

          const filtro = {
            colaborador: this.colaborador.id,
            parceiro: this.parceiroId,
            servico: this.servicoEstaSelecionado ? this.servicoId : null,
          };
          this.getAgendaColaboradorCalendario(filtro);
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo,
          })
        })
    },

    agendaPost() {
      if (this.qtdAtendimento > 0) {
        if (!this.servicoEstaSelecionado) {
          this.$root.vtoast.show({
            status: 'warning',
            title: 'Selecione um Serviço para realizar o Agendamento',
            text: 'ATENÇÃO!',
          });

          return;
        }

        const agenda = {
          colaborador: this.colaborador.id,
          especializacao: this.colaborador.idEspecializacao,
          relacaoDiaQuantidade: [
            { quantidade: this.qtdAtendimento, dia: this.dataCalendar }
          ],
          servicoDisponivel: this.servicoId,
        };

        api
          .postAgendaColaborador(agenda)
          .then(({ data }) => {
            this.$root.vtoast.close();
            this.agendaDia = data
            this.disponibilidadeColaboradorCalendario(this.colaborador.id)

            const filtro = {
              colaborador: this.colaborador.id,
              parceiro: this.parceiroId,
              servico: this.servicoEstaSelecionado ? this.servicoId : null,
            };
            this.getAgendaColaboradorCalendario(filtro);
          })
          .catch((erro) => {
            const mensagemErro = erro.response.data;
            this.$root.vtoast.show({
              status: 'error',
              title: mensagemErro.titulo || this.mensagemErroPadrao,
              text: mensagemErro.mensagemUsuario || '',
            });
          });

        this.closeConfirmationModal();

      } else if (this.qtdAtendimento <= 0 || !this.qtdAtendimento) {
        this.dialog = true;
        this.$root.vtoast.show({
          status: 'warning',
          title: 'ATENÇÃO!',
          text: 'Para criar uma agenda, adicione pelo menos 1 (um) atendimento.',
        });
      }
    },

    agendaPut() {
      if (this.qtdAtendimento > 0) {
        if (!this.servicoEstaSelecionado) {
          this.$root.vtoast.show({
            status: 'warning',
            title: 'Selecione um Serviço para realizar o Agendamento',
            text: 'ATENÇÃO!',
          });

          return;
        }

        const agenda = {
          colaborador: this.colaborador.id,
          dia: this.dataCalendar,
          especializacao: this.colaborador.idEspecializacao,
          quantidade: Number(this.qtdAtendimento),
          servicoDisponivel: this.servicoId
        }

        api
          .putAgendaColaborador(agenda)
          .then(() => {
            api
              .getHorariosByColaborador({
                data: this.dataCalendar,
                servicoId: this.servicoId,
                colaboradorId: this.colaborador.id
              })
              .then(({ data }) => {
                this.agendaDia = data
              })
              .catch((err) => {
                this.$root.vtoast.show({
                  status: 'error',
                  text: err.response?.data.mensagemUsuario,
                  title: err.response?.data.titulo,
                })
              })
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo,
            })
          })
      }
    },

    openModalAntedimentos(tag) {
      this.tagDialog = tag
      if (tag === 'edit') {
        this.isManual = true
      }
      this.dialog = true
    },

    getTimeLineColaborador(data) {
      this.dataCalendar = data
      const idColaborador = this.colaborador.id

      api
        .getDisponibilidadeByData({ id: idColaborador, data })
        .then(({ data }) => {
          this.disponibilidadeAtual = data
        })

      api
        .getHorariosByColaborador({
          data,
          servicoId: this.servicoEstaSelecionado ? this.servicoId : null,
          colaboradorId: idColaborador
        })
        .then(({ data }) => {
          this.agendaDia = data
        })
        .catch((err) => {
          this.agendaDia = [];
          if (err.response.status !== 404) {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            });
            return;
          }

          this.openModalAntedimentos('add');
        })
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault()
      }
    },

    resetarComponentes({servico, parceiro, colaborador, calendario, timeline}) {
      servico && this.keyServicos++;
      parceiro && this.keyParceiro++;
      colaborador && this.keyColaborador++;
      calendario && this.keyCalendario++;
      timeline && this.keyTimeLine++;
    }
  },

  computed: {
    dialogTitle() {
      if (this.tagDialog === 'add') {
        return `Adicionar Agenda - ${this.dataCalendar}`
      }

      if (this.tagDialog === 'edit') {
        return `Editar Agenda - ${this.dataCalendar}`
      }

      if (this.tagDialog === 'delete') {
        return `Excluir Agenda - ${this.dataCalendar}`
      }
    }
  },
}
</script>

<style scoped>
  .aviso-cancelamento-agendamento {
    color: #C62828 ;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    font-size: 1rem;
    padding-left: 0.3rem;
  }

  .aviso-cancelamento-agendamento > li {
    list-style-type: '- ';
  }

  .aviso-cancelamento-agendamento > li:not(:last-child) {
    margin-bottom: 0.4rem;
  }
</style>
