import { render, staticRenderFns } from "./Agenda.vue?vue&type=template&id=f260ba26&scoped=true&"
import script from "./Agenda.vue?vue&type=script&lang=js&"
export * from "./Agenda.vue?vue&type=script&lang=js&"
import style0 from "./Agenda.vue?vue&type=style&index=0&id=f260ba26&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f260ba26",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VBtnToggle,VCol,VContainer,VRow,VSimpleTable,VTextField})
