<template>
  <v-switch
    v-model="condition"
    hide-details="auto"
    @change="getSwitchState"
    :label="label"
  ></v-switch>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      type: String,
    },
    value: Boolean,
  },
  data() {
    return {
      condition: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.condition = newVal;
      },
    },
  },
  methods: {
    getSwitchState() {
      this.$emit("swtichState", this.condition);
    },
  },
};
</script>

<style></style>
